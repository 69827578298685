import React, { useState } from "react"
import emailjs from "@emailjs/browser"

export default function Page() {
  const [email, setEmail] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [error, setError] = useState("")

  const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)

  const handleEmailChange = (e) => {
    const newEmail = e.target.value
    setEmail(newEmail)
    setError(newEmail && !validateEmail(newEmail) ? "Please enter a valid email address" : "")
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!email || isLoading || !validateEmail(email)) return
    setIsLoading(true)
    setError("")

    try {
      const templateParams = {
        to_email: "elsheikhmoha@gmail.com",
        from_email: email,
        message: `New subscription request from: ${email}`
      }

      await emailjs.send(
        "service_jpmxpty",
        "template_rj2i6m5",
        templateParams,
        "lqAqCMEOytAcPPIK0"
      )

      setIsSuccess(true)
      // Set a timeout to revert the button color back to normal after 2 seconds
      setTimeout(() => {
        setIsSuccess(false)
      }, 2000) // 2000 milliseconds = 2 seconds

      setEmail("")
    } catch (error) {
      setError("Failed to subscribe. Please try again.")
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <main className="flex min-h-screen flex-col items-center justify-center bg-black text-white px-6 py-10 md:flex-row md:justify-between md:px-16 lg:px-24">
      <div className="text-center md:text-left md:w-1/2 lg:w-1/3 space-y-6">
        <div className="flex items-center justify-center md:justify-start gap-2">
          <img src="/logo.png" alt="Sepeo Logo" className="h-12" />
          <span className="text-7xl font-medium">sepeo</span>
        </div>
        <h1 className="text-5xl font-normal leading-tight">
          The world&apos;s first all-in-one <br /> travel health companion
        </h1>
        <p className="text-2xl font-light text-gray-400">
          Your seamless travel health solution, from start <br /> to finish, wherever you go
        </p>
        <form onSubmit={handleSubmit} className="flex flex-col gap-4 md:flex-row">
          <div className="relative flex-grow">
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={handleEmailChange}
              className="w-full px-4 py-3 rounded bg-white text-black placeholder-gray-500 text-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            {error && (
              <div className="mt-2 text-sm text-red-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="inline-block h-4 w-4 mr-1"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M12 2a10 10 0 100 20 10 10 0 000-20z" />
                </svg>
                {error}
              </div>
            )}
          </div>
          <button
            type="submit"
            disabled={isLoading || !email || !!error}
            className={`w-full md:w-auto py-3 px-6 rounded ${isSuccess ? "bg-green-500" : "bg-[#EBC8A9]"} text-black text-lg hover:bg-[#EBC8A9]/90 focus:outline-none disabled:opacity-50`}
          >
            {isLoading ? (
              <div className="animate-spin h-5 w-5 border-2 border-t-transparent border-black mx-auto" />
            ) : isSuccess ? (
              <span className="flex items-center justify-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="h-5 w-5"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
                Subscribed!
              </span>
            ) : (
              "Notify Me"
            )}
          </button>
        </form>
        <div className="flex items-center gap-1">
          <img src="/plan.png" alt="Sepeo Logo" className="h-12" />
          <p className="text-2xl font-medium text-gray-400">Landing Early 2025</p>
        </div>
      </div>
      <div className="mt-10 md:mt-0 md:w-1/2 lg:w-1/3">
        <div className="relative w-full h-full flex items-center justify-center">
          <img
            src="/sepeo_hand_iphone.png"
            alt="Sepeo App Interface"
            className="w-full max-w-[300px] md:max-w-none object-contain"
          />
        </div>
      </div>
    </main>
  )
}
